// export const baseurl = "http://172.168.20.53:8082";
// export const BASEURL = "http://172.168.20.53:8082";
// export const socketUrl = "ws://172.168.20.6:8082/ws-endpoint";

// export const baseurl = "https://wasaapi.suffixit.com";
// export const BASEURL = "https://wasaapi.suffixit.com";
// export const APP_BASE_URL = "https://wasa.suffixit.com/";

// export const baseurl = "http://172.168.20.218:8082";
// export const BASEURL = "http://172.168.20.218:8082";
// export const socketUrl = "ws://172.168.20.6:8082/ws-endpoint";

// export const baseurl = "http://172.168.20.26:8082";
// export const BASEURL = "http://172.168.20.26:8082";
// export const socketUrl = "ws://172.168.20.26:8082/ws-endpoint";

// export const baseurl = "http://172.168.21.75:8082";
// export const BASEURL = "http://172.168.21.75:8082";
// export const socketUrl = "ws://172.168.21.75:8082/ws-endpoint";

//=============== production_api==================

export const baseurl = "https://epiciotapi.suffixit.com";
export const BASEURL = "https://epiciotapi.suffixit.com";
export const socketUrl = "wss://epiciotapi.suffixit.com/ws-endpoint";

//===============partho_dash==================

// export const baseurl = "http://172.168.21.95:8082";
// export const socketUrl = "ws://epiciotapi.suffixit.com:8082/ws-endpoint";

//===============Nasim_Vhai==================

// export const baseurl = "http://172.168.23.85:8082";
// export const socketUrl = "ws://172.168.23.85:8082/ws-endpoint";
