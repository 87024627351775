import React from "react";
import { LoadingElectricity } from "../../commom/LoadingLoader";

function FullScreenLoader() {
  return (
    <div className="h-screen flex justify-center items-center bg-black/5">
      <LoadingElectricity />
    </div>
  );
}

export default FullScreenLoader;
