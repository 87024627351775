import { Client } from "@stomp/stompjs";
import PropTypes from "prop-types";
import { createContext, useEffect, useState } from "react";
import { socketUrl } from "../../config/baseURL";

export const NotificationContext = createContext();
const { Provider } = NotificationContext;
const unitId = localStorage?.getItem("unitId");
function NotificationProvider({ children = null }) {
  const [storeNotification, setStoreNotification] = useState({});
  const unitId = localStorage.getItem("unitId");
  useEffect(() => {
    if (unitId) {
      const userClient = new Client({
        brokerURL: socketUrl,
        debug: function (str) {
          // console.log(str);
        },
      });

      userClient.onConnect = function (frame) {
        // console.log("User connected====>>>>> " + frame);

        userClient.subscribe(
          `/user/exchange_${unitId}/queue/notifications`,
          (message) => {
            // console.log("Notification received====>>>: ", message.body);
            try {
              const notification = JSON.parse(message.body);
              setStoreNotification(notification);
              console.log("Notification content====>>> ", notification);
            } catch (e) {
              // console.error("Failed to parse notification message:", e);
            }
          }
        );
      };

      userClient.onWebSocketError = (error) => {
        console.error("Error with websocket", error);
      };

      userClient.activate();

      return () => {
        if (userClient.connected) {
          userClient.deactivate();
        }
      };
    }
  }, [unitId]);
  const value = [storeNotification];

  return <Provider value={value}>{children}</Provider>;
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationProvider;
